function MenuOverlay(parent){

    var self = this;

    //self.menuVisible = mV;

    self.$menuToggle = $("#show-menu");
    TweenMax.set(self.$menuToggle , {rotation:0});


    self.$menuWrapper = $(".menu-wrapper");
    self.$logo = $("#logo");
    self.browserSize = new BrowserSize();

    self.circle = {};

    self.circle.radius = 0;
    self.circle.x = 0;
    self.circle.y = 0;


    $( window ).resize(function() {

        self.browserSize.getWindowSize();

        self.setTargetRadius();

        self.setCircleCanvas();

        if (parent.menuVisible) {

            console.log("- self.targetRadius " + self.targetRadius);

            self.setCircle(self.targetRadius);

        }

    });

    //self.browserSize.getWindowSize();

    //self.setTargetRadius();

   // self.setCircleCanvas();

   // self.setCircle(self.targetRadius);

   // self.animateCircle(0);

    //self.setInitial();

}
MenuOverlay.prototype.setInitial = function (){

    console.log("----------- setInitial");

    var self = this;

    self.browserSize.getWindowSize();

    self.setTargetRadius();

    self.setCircleCanvas();

    self.setCircle(self.targetRadius);
};
MenuOverlay.prototype.setTargetRadius = function (){

    var self = this;

    self.targetRadius = Math.sqrt((self.browserSize.width * self.browserSize.width) + (self.browserSize.height * self.browserSize.height));
};
MenuOverlay.prototype.setCircleCanvas = function (){

    console.log("--------------------- set circle canvas");

    var self = this;

    var pos = $("#show-menu").position();
    var oTop = $("#show-menu").offset().top;
    var oLeft = $("#show-menu").offset().left;

    //console.log("--------------------- Pos " + pos.top + " " + pos.left);
    //console.log("--------------------- Offset " + oTop + " " + oLeft);


    //console.log("--------------------- Width " + self.browserSize.width);


    /*
     xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl:1450px,
     */

    self.circle.x = pos.left + 25;
    //self.circle.y = pos.top + 25;
    self.circle.y = 70 + 25;

    if (self.browserSize.width > 1449){

        console.log("xxl");

    } else if (self.browserSize.width > 1199 && self.browserSize.width < 1450){

        console.log("xl");
    }else if (self.browserSize.width > 991 && self.browserSize.width < 1200){
        console.log("lg");

        self.circle.y = 50 + 25;

    }else if (self.browserSize.width > 767 && self.browserSize.width < 992){

        console.log("md");

        self.circle.y = 50 + 25;

    }else if (self.browserSize.width > 575 && self.browserSize.width < 768){

        console.log("sm");

        self.circle.y = 10 + 25;

    } else {

        console.log("xs");

        self.circle.y = 10 + 25;
    }

    self.mainCanvas = document.getElementById("circle-canvas");
    self.mainContext = self.mainCanvas.getContext('2d');

    self.mainCanvas.width = self.browserSize.width;
    self.mainCanvas.height = self.browserSize.height;


    /*


     @include media-breakpoint-up(md) {

      top:50px;
      left:45px;

    }
    @include media-breakpoint-up(xl) {

      top:70px;
      left:65px;

    }


     */






   // self.circle.x = oLeft;
    //self.circle.y = oTop;

    console.log(self.circle.x);

    TweenMax.set("#circle-canvas", {display:"none"});

    console.log("setOverlayCanvas finished");
};
MenuOverlay.prototype.setCircle = function (r){

    console.log("main canvas ", this.mainCanvas.width, this.mainCanvas.height);

    var self = this;

    this.circle.radius = r;

    self.drawCircleCanvas();

    if (this.circle.radius === 0){

        // TweenMax.set("#circle-canvas", {autoAlpha:0});
        TweenMax.set("#circle-canvas", {display:"none"});

    } else {

        TweenMax.set("#circle-canvas", {display:"block"});
    }

};
MenuOverlay.prototype.drawCircleCanvas = function (){

    var circle = this.circle;

    this.mainContext.clearRect(0, 0,  this.mainCanvas.width, this.mainCanvas.height);

    this.mainContext.beginPath();
    this.mainContext.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2, false);
    this.mainContext.fillStyle ="#ffffff";
    this.mainContext.fill();
    this.mainContext.closePath();

};

MenuOverlay.prototype.animateCircle = function (r){

    var self = this;

    //console.log("main canvas ", self.mainCanvas.width, self.mainCanvas.height);
    console.log("--------------------- XY " + self.circle.x + " " + self.circle.y);

    var circle = self.circle;

    function animateComplete() {

        TweenMax.ticker.removeEventListener("tick", self.gsapTickAnimate);

        console.log("animateComplete circle.radius " + circle.radius);

        if (circle.radius === 0){

            TweenMax.set("#circle-canvas", {display:"none"});
        }

    }
    function gsapTickAnimate(){

        self.drawCircleCanvas();
    }

    TweenMax.set("#circle-canvas", {display:"block"});

    TweenMax.to(circle, 1, {radius:r, ease: Sine.easeInOut, onComplete:animateComplete});
    TweenMax.ticker.addEventListener("tick", gsapTickAnimate);


};
MenuOverlay.prototype.drawCircle = function (){

    var circle = this.circle;

    this.mainContext.clearRect(0, 0,  this.mainCanvas.width, this.mainCanvas.height);

    this.mainContext.beginPath();
    this.mainContext.arc(circle.x, circle.y, circle.radius, 0, Math.PI * 2, false);
    this.mainContext.fillStyle ="#ffffff";
    this.mainContext.fill();
    this.mainContext.closePath();

};
MenuOverlay.prototype.showOverlay = function (){

    var self = this;

    self.animateCircle(self.targetRadius);
};


MenuOverlay.prototype.animateHideOverlay = function (){

    var self = this;

    self.animateCircle(0);

};

MenuOverlay.prototype.hideOverlay = function (){

    var self = this;

    self.hideCircle(0);
};

MenuOverlay.prototype.hideCircle = function (r){

    var self = this;

    console.log("main canvas ", self.mainCanvas.width, self.mainCanvas.height);

    var circle = self.circle;

    function animateComplete() {

        TweenMax.ticker.removeEventListener("tick", self.gsapTickAnimate);

        console.log("animateComplete circle.radius " + circle.radius);

        if (circle.radius === 0){

            TweenMax.set("#circle-canvas", {display:"none"});
        }

    }
    function gsapTickAnimate(){

        self.drawCircleCanvas();
    }

    //TweenMax.set("#circle-canvas", {autoAlpha:1});
    TweenMax.set("#circle-canvas", {display:"block"});

    TweenMax.to(circle, 0.1, {radius:r, ease: Sine.easeInOut, onComplete:animateComplete});
    TweenMax.ticker.addEventListener("tick", gsapTickAnimate);


};
