function BrowserSize(){

   // console.log("size");

    //this.getWindowSize();
    var self = this;

    self.height = 0;
    self.width= 0;

}
BrowserSize.prototype.getWindowSize = function (){

    var self = this;

	if (document.body && document.body.offsetWidth) {

        self.width = document.body.offsetWidth;
        self.height = document.body.offsetHeight;
	
	}
	if (document.compatMode==='CSS1Compat' && document.documentElement && document.documentElement.offsetWidth ) {

        self.width = document.documentElement.offsetWidth;
        self.height = document.documentElement.offsetHeight;
	 
	}
	if (window.innerWidth && window.innerHeight) {

        self.width = window.innerWidth;
        self.height = window.innerHeight;
	 
	}
	
	return {width : self.winW, height : self.winH};
};

BrowserSize.prototype.getDocHeight = function (){
	var D = document;
    return Math.max(
        Math.max(D.body.scrollHeight, D.documentElement.scrollHeight),
        Math.max(D.body.offsetHeight, D.documentElement.offsetHeight),
        Math.max(D.body.clientHeight, D.documentElement.clientHeight)
    );
};


BrowserSize.prototype.checkSize = function (){

    var self = this;
	
	var d = this.getWindowSize();
	
	self.width = d.width;
    self.width = d.height;
	
};