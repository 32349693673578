function Navigation(){

    /*

     try {
     Typekit.load({
     loading: function() {
     // JavaScript to execute when fonts start loading
     },
     active: function() {
     // JavaScript to execute when fonts become active
     },
     inactive: function() {
     // JavaScript to execute when fonts become inactive
     }
     })
     } catch(e) {}

     */

    console.log("content loader");

    var self = this;

    var $link = $(".menu-item a");

    self.menu = new Menu();
    self.url = "http://" +document.domain;

    self.$loaderWrapper = $("#loader-wrapper");
    self.$loaderContent = $("#loader-content");

    self.ajaxLoader = new AjaxLoader(self.url, self.$loaderWrapper, self.$loaderContent,self);

    /*$link.click(function(e){

        e.preventDefault();

        $url = $(this).attr("href");

        console.log("click  do some ajax shit " + $url);

        self.ajaxLoader.load($url);

    });*/

}

Navigation.prototype.loadFinished = function (){

    console.log("load finished");

    var self = this;

    self.menu.hideMenu();

};
