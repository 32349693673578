function Menu(){

    var self = this;

    self.menuVisible = false;

    self.scroller = new Scroller();

    self.$menuBar = $("#menu-bar");

    self.$menuToggle = $("#show-menu");
    TweenMax.set(self.$menuToggle , {rotation:0});

    self.$menuWrapper = $(".menu-wrapper");
    self.$logo = $("#logo");
    self.browserSize = new BrowserSize();

    self.menuOverlay = new MenuOverlay(self);



    self.$menuToggle.click(function(){

        console.log("toggle clicked");

        if (!self.menuVisible){

           // $('body').addClass("no-scroll");

           self.animateShowMenu();



        } else {

            //$('body').removeClass("no-scroll");

          //  $('body').unbind('mousewheel touchmove', lockScroll);

            self.animateCloseMenu();

        }
        console.log("$menuToggle");

    });
    self.$menuToggle.mouseover(function(){

        TweenMax.to("#show-menu", 0.22, {scaleX:1.3, scaleY:1.3, ease:Sine.easeOut});

    }).mouseout(function(){

        TweenMax.to("#show-menu", 0.5, {scaleX:1, scaleY:1});
    });

    self.$logo.mouseover(function(){

        TweenMax.to("#logo", 0.22, {scaleX:1.3, scaleY:1.3, ease:Sine.easeOut});

    }).mouseout(function(){

        TweenMax.to("#logo", 0.5, {scaleX:1, scaleY:1});
    });

    $('.menu-item a').click(function(){

        $('.menu-item').removeClass("active");

        $(this).parent().addClass("active");
    });

    $('a').click(function(event){

        var href = $(this).attr('href');
        var url = window.location.href;

        if (href===url){

            console.log("same and close");
            event.preventDefault();

            self.animateCloseMenu();
        }

    });

    self.$social = $(".social-menu a");

    self.$social.mouseover(function(){

        TweenMax.to($(this), 0.22, {scaleX:1.3, scaleY:1.3, ease:Sine.easeOut});

    }).mouseout(function(){

        TweenMax.to($(this), 0.5, {scaleX:1, scaleY:1});
    });
}
Menu.prototype.hideOverlayOnInitialLoad = function (){

    var self = this;

    self.menuOverlay.setInitial();

    TweenMax.set("#loader-wrapper",{autoAlpha:1});
    TweenMax.set(".overlay",{autoAlpha:0});

    $('body').addClass("initial-loaded");

    self.menuOverlay.animateCircle(0);

};
Menu.prototype.animateShowMenu = function (){

    var self = this;

    $('body').bind('mousewheel touchmove', self.lockScroll);

    TweenMax.killAll();

    self.menuVisible = true;
    self.menuOverlay.showOverlay();
    self.$menuBar.addClass('open');
    self.$menuToggle.addClass('open');

    TweenMax.set('.menu-wrapper .social', {autoAlpha:0, y:40});

    TweenMax.staggerTo(".menu-wrapper .social", 1, {y:0, delay:0.8, ease:Sine.easeOut}, 0);
    TweenMax.staggerTo(".menu-wrapper .social", 1, {autoAlpha:1,delay:0.8, ease:Sine.easeInOut}, 0.15);

    TweenMax.set(self.$menuWrapper, {"display" : "block"});
    TweenMax.set('.menu-wrapper .menu-item', {autoAlpha:0, y:120, transformOrigin: "center center"});
    TweenMax.staggerTo(".menu-wrapper .menu-item", 1.2, {autoAlpha:1, delay:0.8}, 0.1);
    TweenMax.staggerTo(".menu-wrapper .menu-item", 1.2, {y:0, ease:Power4.easeOut, delay:0.6, transformOrigin: "center center"}, 0.1);
    TweenMax.to("#show-menu", 0.6, {rotation:270});

    function changeLogo(){

        self.$logo.addClass('open');
    }

    TweenLite.delayedCall(0.5, changeLogo);

};

Menu.prototype.animateCloseMenu = function (){

    console.log("hidemenu");

    var self = this;

    TweenMax.killAll();

    $('body').unbind('mousewheel touchmove', self.lockScroll);

    TweenMax.to("#show-menu", 0.6, {rotation:0});

    self.menuVisible = false;

    self.menuOverlay.animateHideOverlay();

    self.$logo.removeClass('open');

    function changeToggle(){

        self.$menuToggle.removeClass('open');
        self.$menuBar.removeClass('open');
    }

    TweenLite.delayedCall(0.85, changeToggle);

    function hideWrapper(){
        TweenMax.set(self.$menuWrapper, {"display" : "none"});
    }

    TweenMax.to('.menu-wrapper .social', 0.5, {autoAlpha:0});
    TweenMax.to('.menu-wrapper .menu-item', 0.5, {autoAlpha:0,onComplete:hideWrapper});

};

Menu.prototype.hideMenu = function (){

    var self = this;

    $('body').unbind('mousewheel touchmove', self.lockScroll);

    TweenMax.set("#show-menu", {rotation:0});
    self.menuVisible = false;
    self.menuOverlay.hideOverlay();
    self.$logo.removeClass('open');
    self.$menuBar.removeClass('open');
    self.$menuToggle.removeClass('open');
    TweenMax.set('.menu-wrapper .social', {autoAlpha:0});
    TweenMax.set('.menu-wrapper .menu-item', {autoAlpha:0});
    TweenMax.set(self.$menuWrapper, {"display" : "none"});

};

Menu.prototype.lockScroll = function (e){

        e.preventDefault();

};