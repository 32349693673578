function Arrow(){

    console.log("arrow");

    $(".arrow").on( "tap", function( e ) {
    //$(".arrow").on( "click", function( e ) {

        e.preventDefault();

        var scrollYPos = ($("#c1").offset().top);

        console.log("scrollYPos " + scrollYPos);

       // TweenMax.to(".arrow", 0.5, {autoAlpha:0.25});

        TweenMax.to(window, 1, {scrollTo: {y: scrollYPos, x: 0, autoKill:false}, ease: Power2.easeOut});

    });

}
