function ExpandingTextfield(){

    console.log("has ExpandingTextfield");


    $('.ginput_container_textarea').each(function(index, element){

        $(this).prepend( "<pre><span></span><br></pre>");

    });


    function makeExpanding(c) {

        var area = c.querySelector('textarea');
        var span = c.querySelector('span');

        console.log("jquery area");

        //var area = $(c + ' textarea');

        if (area.addEventListener) {

            area.addEventListener('input', function() {

                ////console.log(area.value)
                span.textContent = area.value;
            }, false);

            span.textContent = area.value;

        } else if (area.attachEvent) {

            // IE8 compatibility
            area.attachEvent('onpropertychange', function() {
                span.innerText = area.value;
            });

            span.innerText = area.value;

        }

        $(c).addClass('active');

    }

    //if( $('.image-tablet-carousel', this.newContainer).length )

    var areas = document.querySelectorAll('.ginput_container_textarea');

    var l = areas.length; while (l--) {

        console.log("textarea");

        makeExpanding(areas[l]);

    }
}

