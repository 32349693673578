function PageTransitions(){

    Barba.Pjax.start();

    //Barba.Prefetch.init();

    Barba.Dispatcher.on('linkClicked', function(element, event) {

        console.log("linked clicked = ", element, event);

        var href= $(element).attr("href");

        console.log("href = " + href);

        var current = window.location.href;
        var hostname= window.location.hostname;
        var pathname= window.location.pathname;

        console.log("current " + current);
        console.log("hostname " + hostname);
        console.log("pathname " + pathname);


    });
    //Barba.Dispatcher.on('initStateChange', function(element, event) {


        //console.log("initStateChange  = ", location.pathname);

        //ga('send', 'pageview', location.pathname);

        //ga('send', 'pageview', location.pathname, {
            //hitCallback: function() {
              //  console.log("-------------------- sent ");
           // }
       // });

    //});



    Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

    Barba.Pjax.preventCheck = function(evt, element) {

        if (!Barba.Pjax.originalPreventCheck(evt, element)) {

            return false;
        }

        // No need to check for element.href -
        // originalPreventCheck does this for us! (and more!)


       // var current = window.location.href;

        //console.log("current " + current);

        //console.log("e " +element + " " + current);

        if (/.pdf/.test(element.href.toLowerCase())) {
            return false;
        }
        if (element.className === 'ab-item') {

            console.log("prevent click on admin item");

            return false;
        }
        if (element.className === 'ab-submenu') {

            console.log("prevent click on admin item");

            return false;
        }

       // if (element === current){

          //  console.log("ame url");

           // return false;
        //}


        return true;
    };

    var HideShowTransition = Barba.BaseTransition.extend({
        start: function() {


            console.log("start");

            var _this = this;

            //var $el = $(this.newContainer);




            function finishedStart(){

                console.log("finishedStart");

                _this.newContainerLoading.then(_this.finish.bind(_this));

                control.menu.hideMenu();
            }

            TweenMax.set(".overlay", {});

            TweenMax.to(".overlay", 0.5, {autoAlpha:1, onComplete:finishedStart});

        },

        finish: function() {

            console.log("finish");

            TweenMax.set(window, {scrollTo: {y: 0}});

            //TweenMax.set(window, {scrollTo: {y: 20}});

            $(this.oldContainer).hide();

            var $el = $(this.newContainer);

            $el.css({
                visibility : 'visible',
                opacity : 1
            });

            var _this = this;




            if( $('.image-tablet-carousel', this.newContainer).length )         // use this if you are using id to check
            {

                console.log("has tablet in new container");

                $('.image-tablet-carousel').each(function(index, element){

                    console.log("call swiper");

                    $(".swiper-container", this).addClass('s'+index);

                    var slider = new Swiper('.s'+index, {

                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },

                        speed: 900,
                        // paginationClickable: true,
                        autoplay: 2500,
                        // effect: 'fade',
                        loop: true
                    });
                    //sliders.push(slider);

                });

            }
            if( $('.image-laptop-carousel', this.newContainer).length )         // use this if you are using id to check
            {

                $('.image-laptop-carousel').each(function (index, element) {

                    console.log("call swiper");

                    $(".swiper-container", this).addClass('s' + index);

                    var slider = new Swiper('.s' + index, {

                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true
                        },

                        speed: 900,
                        autoplay: 2500,
                        loop: true
                    });

                });
            }
            if( $('.social-contact', this.newContainer).length )         // use this if you are using id to check
            {

                self.$social = $(".social-contact a");

                self.$social.mouseover(function () {

                    TweenMax.to($(this), 0.22, {scaleX: 1.3, scaleY: 1.3, ease: Sine.easeOut});

                }).mouseout(function () {

                    TweenMax.to($(this), 0.5, {scaleX: 1, scaleY: 1});
                });

            }

            if( $('.gform_wrapper', this.newContainer).length )         // use this if you are using id to check
            {
                console.log("has form");

                var expText = new ExpandingTextfield();

            }

            if ( $( ".arrow" ).length ) {

                var down = new Arrow();

            }

            $('#loader-content', this.newContainer).imagesLoaded( function() {

                console.log("------- images have loaded in new container");
                // images have loaded

                TweenMax.to(".overlay", 0.5, {autoAlpha:0, delay:0.5, onComplete:finishedFinish});
            });


            function finishedFinish(){

                console.log("------- finished transition");

                console.log("------- send to analytics gtag and config ", location.pathname);

                //ga('send', 'pageview', location.pathname);

               // ga('send', 'pageview', location.pathname, {
                   // hitCallback: function() {
                    // console.log("-------------------- sent ");
                    // }

               // });

                //ga('send', {
                  //  hitType: 'pageview',
                  //  page: location.pathname,
                  //  hitCallback: function() {
                    //    console.log("-------------------- sent ");
                    //}

                //});

                //gtag('event', 'page_view', { 'send_to': 'UA-91763317-1' });

                gtag('config', 'UA-91763317-1', {'page_path': location.pathname});

                _this.done();
            }

        }
    });

    Barba.Pjax.getTransition = function() {
        return HideShowTransition;
    };

    /**
     * Next step, you have to tell Barba to use the new Transition
     */



}