/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

var $ = jQuery;

var control = {};

//function myonloadscript(){

    //console.log("iframe loaded");
//}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          if ( $( ".arrow" ).length ) {

              var down = new Arrow();
          }

          if( $('.page-template-template-form').length )  {

              console.log("form page 1");

          } else {

              var pageTransitions = new PageTransitions();

              control.menu = new Menu();

              $('.image-tablet-carousel').each(function(index, element){

                  console.log("call swiper");

                  $(".swiper-container", this).addClass('s'+index);

                  var slider = new Swiper('.s'+index, {

                      pagination: {
                          el: '.swiper-pagination',
                          clickable: true,
                      },

                      speed: 900,
                      // paginationClickable: true,
                      autoplay: 2500,
                      // effect: 'fade',
                      loop: true
                  });
                  //sliders.push(slider);

              });

              $('.image-laptop-carousel').each(function(index, element){

                  console.log("call swiper");

                  $(".swiper-container", this).addClass('s'+index);

                  var slider = new Swiper('.s'+index, {

                      pagination: {
                          el: '.swiper-pagination',
                          clickable: true
                      },

                      speed: 900,
                      autoplay: 2500,
                      loop: true
                  });

              });

              $('#loader-content').imagesLoaded( function() {

                  console.log("----------- images have loaded");

                  function delayedCall(){

                      console.log("dealyedCall");

                      control.menu.hideOverlayOnInitialLoad();
                  }

                  TweenMax.delayedCall(0.5,delayedCall);
              });
          }



          if( $('.social-contact').length ){

              var $social = $(".social-contact a");

              $social.mouseover(function () {

                  TweenMax.to($(this), 0.22, {scaleX: 1.3, scaleY: 1.3, ease: Sine.easeOut});

              }).mouseout(function () {

                  TweenMax.to($(this), 0.5, {scaleX: 1, scaleY: 1});
              });

          }




        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_template_form': {
        init: function () {
            // JavaScript to be fired on the about us page
        }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
